import React from "react";
import theme from "theme";
import { Theme, Text, Image, Link, LinkBox, Box, Section, List, Em, Span, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"portfolio/ukitalt"} />
		<Helmet>
			<title>
				Кейс uKit Alt | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"uKit Alt — это дополнение к конструктору uKit, с помощью которого пользователи Facebook и VK могут сделать сайт из своей группы или профиля."} />
			<meta property={"og:title"} content={"Кейс uKit Alt | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"uKit Alt — это дополнение к конструктору uKit, с помощью которого пользователи Facebook и VK могут сделать сайт из своей группы или профиля."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header mix-blend-mode="difference" sm-padding="0px 0px 0px 0px">
				<Override slot="link" color="--darkL2" />
				<Override slot="link1" color="--darkL2" />
				<Override slot="link2" color="--darkL2" />
			</Components.Header>
			<Section quarkly-title="Cover" margin="50px 0 0px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="center"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					grid-column="2/3"
					display="flex"
					flex-direction="row"
					flex-wrap="wrap"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--headline2"
						width="100%"
						sm-font="--headline3"
					>
						юКит Альт
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						юКит Альт — это дополнение к конструктору юКит, с помощью которого пользователи Facebook и VK могут сделать сайт из своей группы или профиля.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="66%"
						padding="0px 16px 0px 0px"
						sm-width="100%"
						sm-height="auto"
						sm-margin="0px 0px 18px 0px"
					>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
							/>
							<Link
								href="ukit.com/ru/lp/convert"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								ukit.com/ru/lp/convert
							</Link>
						</LinkBox>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
							/>
							<Link
								href="https://ukit.com/ru/lp/convert-vk"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								ukit.com/ru/lp/convert-vk
							</Link>
						</LinkBox>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
							/>
							<Link
								href="https://ukit.com/ru/lp/alt-vk"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								ukit.com/ru/lp/alt-vk
							</Link>
						</LinkBox>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Дата окончания работ: 1 июля 2020
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Сделано за 240 дней
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="33%"
						sm-width="100%"
						sm-height="auto"
						sm-min-height="auto"
						sm-margin="0px 0px 0px 0px"
					>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							UX / UI дизайн
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Продукт дизайн
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Дизайн-менеджмент
						</Text>
					</Box>
				</Box>
			</Section>
			<Section quarkly-title="YoutubeVideo" margin="0 0 12px 0" inner-width="100%">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/6kpbtvJydHs"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase />
				<Text
					margin="18px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделать конвертацию Facebook-страниц пользователей в сайты на конструкторе юКит.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="12px 0px 0px 0px"
				>
					Вторым этапом сделать конвертацию VK-страниц.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" sm-margin="0px 24px 16px 0px" margin="24px 24px 0px 0px">
					МОЯ РОЛЬ
				</Components.BaseUppercase>
				<Text
					margin="18px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
					min-height="auto"
					height="auto"
				>
					Здесь я был в роли продакт менеджера и UX/UI дизайнера.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					В процессе работы я придумал интерфейс пошагового визарда, онбординга и мини-конструктора. Далее я адаптировал существующие шаблоны конструктора под данные из соцсетей.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Вдвоем с фулстек программистом мы работали над алгоритмом получения и распределения данных по шаблону.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Проводил юзабилити тестирования, отвечал на вопросы в поддержку, собирал фидбек и статистические отчеты.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Будучи главным ответственным лицом проекта, я организовывал работу других участников внутри и вне основной команды разработки.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Краткое описание{" "}
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					юКит Альт — это дополнение к конструктору сайтов юКит, которое позволяет создавать сайты используя данные из пользовательских страниц на Facebook и VK, которые забираются через API.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Данные могут использоваться для создания как персональных так и бизнес сайтов. После выбора страницы
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/7buu3fEcxqE"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					После создания версий сайта, пользователь попадает на визард. Здесь ему предлагается выбрать вариант дизайна и тип сайта
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/7hVuMdmH8sg"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					После визарда пользователь попадает в мини-конструктор, где он можно посмотреть сайт, поменять его тип, дизайн и контент.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Переход в мини-конструктор сопровождается онбордингом с подсказками.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/YGk8A67Q9w8"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					юКит Альт это отличный способ привлечь новую аудиторию в юКит и получить более эффективную unit-экономику.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Главная страница
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделать отдельные посадочные страницы для аудитории Facebook и VK
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я придумал и спроектировал адаптивный дизайн посадочных страниц, записал несколько объясняющих видео и оформил примеры созданных сайтов.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-landing-fb-ru-1.png?v=2022-06-18T06:24:17.156Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Посадочная страница для аудитории Facebook
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-landing-vk-another-ru-3.png?v=2022-06-18T06:24:33.384Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Альтернативная страница для аудитории с VK
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-landing-vk-ru-2.png?v=2022-06-18T06:24:17.152Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Cтраница для пользователей VK
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Визард создания сайта
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделать визард, который помогал бы пользователю разобраться с передачей данных со страницы
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я проектировал визард, который просто и наглядно помогает пользователю пройти путь от выбора страницы до готового сайта.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					В местах где некоторое время идет процесс создания сайта, я сделал развлекательную анимацию и описание работ, которые происходят в данную секунду.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					За короткое время пользователь получает шесть различных вариантов сайта, которые он может внимательно посмотреть и проскроллить.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-wizard-group-list-ru-1.png?v=2022-06-18T06:25:40.999Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Модальное окно выбора группы или профиля пользователя
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-wizard-generation-ru-2.png?v=2022-06-18T06:25:52.844Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Анимация процесса с описанием стадий
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-wizard-select-design-ru-3.png?v=2022-06-18T06:26:00.567Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Просмотр получившихся вариантов дизайна
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-wizard-select-type-ru-4.png?v=2022-06-18T06:26:07.373Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Выбор типа сайта: многостраничный или одностраничный
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Онбординг
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Перед переходом пользователя в мини-конструктор, нужно познакомить пользователя с ним и объяснить как здесь работать.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я записал видео объяснения и спроектировал пошаговый онбординг пользователя с описанием каждой секции мини-конструктора.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Пользователь в любой момент может скрыть подсказки онбординга или показать их снова.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-onboarding-step1-ru-1.png?v=2022-06-18T06:26:55.331Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Вводное видео с объяснением работы в мини-конструкторе
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-onboarding-step2-ru-2.png?v=2022-06-18T06:27:07.471Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Описание каждой секции в специальных подсказках
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-onboarding-step5-ru-3.png?v=2022-06-18T06:27:15.398Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Последняя подсказка про режимы превью
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Мини-конструктор
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Интерфейс юКит предоставляет большие возможности для настройки, но это имеет свою цену в виде не прямолинейного интерфейса.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Перед переходом в конструктор юКит и созданием сайта, пользователю нужен простой способ настройки дизайна и содержимого.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я спроектировал простой мини-конструктор, который показывает пользователю получившийся сайт и дает его интерактивно настраивать.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Вся настройка разбита на три секции:
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						тип сайта (одностраничный или многостраничный),
					</Text>
					<Text margin="0px 0px 0px 0px">
						дизайн,
					</Text>
					<Text margin="0px 0px 0px 0px">
						содержимое.
					</Text>
				</List>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Играясь с настройками пользователь подбирает для себя оптимальную вариацию сайта.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Чтобы показать, как дизайн сайта адаптируется, я добавил переключатель устройств.{"  "}
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-website-type-ru-1.png?v=2022-06-18T06:27:58.062Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Слева расположена панель, справа сайт. В этой секции пользователь выбирает тип сайта
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-design-ru-2.png?v=2022-06-18T06:28:05.879Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						В этой секции выбирается дизайн сайта и его цветовая палитра
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-tablet-ru-4.png?v=2022-06-18T06:28:21.818Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Пользователь может посмотреть как его сайт будет выглядеть на мобильном устройстве
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-content-phone-ru-3.png?v=2022-06-18T06:28:13.572Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						В секции «Контент» пользователь выбирает какие блоки будут на сайте
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Результаты
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Такой способ создания сайтов в юКит существует до сих пор и пользуется некоторой популярностью.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Импорт данных из соцсетей стал хорошим способом привлечения пользователей из области с низкой конкуренцией.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					ОТЗЫВЫ
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Span
						font="--base"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<Em>
							«Когда люди интересуются моими футболками, то спрашивают, есть ли у меня сайт, где можно посмотреть все работы. Cайта у меня не было, были лишь Facebook и Instagram — но не все зарегистрированы там. Я решил, что люди должны узнавать о моем бизнесе без преград, запустил конвертер, а за доработкой обратился к фрилансеру, которого рекомендовал ваш сервис. И за пару суток получил готовый сайт».
						</Em>
					</Span>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Константин
					</Strong>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						,{" "}
					</Span>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						дизайнер футболок и принтов
					</Span>
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Link href="http://konstantinkoltin.ru/" target="_blank" color="--primary">
						konstantinkoltin.ru
					</Link>
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						font="--base"
					>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							«Сайт нужен нам для имиджа и полноценного присутствия в интернет-поиске. Сначала думали обращаться к фрилансерам, к агентствам, которые сейчас популярны — но поняли, что не можем себе этого сейчас позволить. Тогда мы просмотрели массу конструкторов, нашли юКит и решили быстро сделать из страницы на Facebook, которая наращивает лайки и популярность, еще и мини-сайт, где человек может записаться к нам»
						</Em>
					</Span>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Александр
					</Strong>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						, совладелец консалтингового бизнеса
					</Span>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em>
						"У нашего старого сайта не было мобильной версии для смартфонов и планшетов. Оплачивать ее отдельно - дорого. Переносить весь сайт на новую платформу - тоже. Мы узнали, что можно перенести страницу в Facebook на адаптивный сайт. В принципе, нам все понравилось. И бесплатная мобильная версия у нас теперь есть".
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<Strong>
							Татьяна
						</Strong>
						, владелица салона постельного белья и штор
					</Span>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					МНЕНИЯ
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em>
						«Это хороший ход — многие предприниматели сейчас начинают в соцсетях, а уже затем решают сделать свой сайт. В интеграции с соцсетями будущее конструкторов сайтов»
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong>
						Анатолий Денисов
					</Strong>
					, главред CMS Magazine
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em>
						«Я попробовала — интересно: выходит просто, но мило; удобно редактировать. Для тех, у кого есть страница в FB, но до сайта все руки не дойдут — отличная находка»
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					<Strong>
						Анна Соколова
					</Strong>
					, шеф-редактор раздела "Бизнес" на The Village, ex-Hopes&Fears
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						«Мне кажется, для многих мелких фирм это хорошее решение. Видно, что в юКит постарались придумать, что и куда распределять на сайте»
					</Em>
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					<Strong>
						Виктория Вирта
					</Strong>
					, основатель «Академии интернет-резерва Вирта», эксперт ИРИ, экс-Rutube и ivi.
				</Text>
			</Section>
			<Components.PrevNextButtons>
				<Override slot="text">
					юКит
				</Override>
				<Override slot="text1">
					СтепФОРМ
				</Override>
				<Override slot="linkBox" href="/portfolio/ukit" />
				<Override slot="linkBox1" href="/portfolio/stepform" />
			</Components.PrevNextButtons>
			<Components.Footer />
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(52528996, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
			<script async={false} src={"https://www.googletagmanager.com/gtag/js?id=G-5FMRH52STD"} place={"endOfHead"} rawKey={"62d57fa6534ed0228e31f79a"} />
			<script place={"endOfHead"} rawKey={"62d57fb994ee59fbdc7b583c"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-5FMRH52STD');"}
			</script>
		</RawHtml>
	</Theme>;
});